<template>
  <ul class="social">
    <li
      class="social__item"
      v-for="{url, name} in socials"
      :key="name"
    >
      <a
          :href="url"
          target="_blank"
          class="social__item-link"
      >
        <img
            :src="getImage(name)"
            class="social__item-image"
            :alt="name"
        />
      </a>
    </li>
  </ul>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'socials-block',
  props: {
    socials: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getImage (name) {
      try {
        return require(`../../img/socials/${name.toLowerCase()}-logo.webp`)
      } catch (e) {
        return require('../../img/socials/black.webp')
      }
    }
  }
})
</script>

<style lang="scss" src="./styles.scss"></style>
