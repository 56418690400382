<template>
  <div class="landing">
    <land-template
      v-if="dataIsLoaded"
      :config="landingData"
      @copy="copyEvent"
    />
    <div
      v-else
      class="preloader"
    >
      <div class="loader">
        <span>Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import LandTemplate from '@/template'
import { useToast } from 'vue-toastification'
import axios from 'axios'

export default {
  name: 'landing-page',
  components: {
    LandTemplate
  },
  data () {
    return {
      isRequestSending: false,
      dataIsLoaded: false,
      landingData: {}
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      const host = window.location.hostname

      const requestUrl = host === 'localhost' ? '/data.json' : `/script.php?host=${host}`

      axios.get(requestUrl, { headers: { 'Content-Type': 'application/json;charset=utf-8' } })
        .then((response) => {
          this.isRequestSending = false

          if (response.status === 200 || response.statusText === 'OK') {
            if (response.data) {
              const landingData = response.data.data

              const projects = landingData.projects.map(project => {
                const casinoBonus1 = project.casinoBonus1 || landingData.casinoBonus1
                const casinoBonus2 = project.casinoBonus2 || landingData.casinoBonus2

                return {
                  ...project,
                  casinoBonus1,
                  casinoBonus2
                }
              })

              this.landingData = { ...landingData, projects }

              console.log(this.landingData)

              this.dataIsLoaded = true

              return
            }

            useToast().error('Ошибка при получении данных')

            return
          }

          console.log('error', response)
          useToast().error('Ошибка при отправке запроса')
        })
        .catch((error) => {
          this.isRequestSending = false
          console.log('request error', error)
          useToast().error('Ошибка при отправке запроса')
        })
    },
    copyEvent () {
      useToast().info(this.landingData.copyMessage)
    }
  }
}
</script>

<style src="./style.scss" lang="scss"></style>
